import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import Rodal from 'rodal'

export const RodalPopupContainer = styled(Rodal)`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.couriernew};
  .rodal-mask {
    background-color: #000000a9;
  }
  .rodal-dialog {
    width: 100% !important;
    max-width: 400px !important;
    height: auto !important;
    bottom: auto;
    top: 25%;
    background-color: white;
    border: solid 2px ${theme.colors.backgroundDark};
    border-radius: 0;
    padding: 40px 60px;
    @media screen and (max-height: 900px) {
      top: 20%;
    }
    @media screen and (max-height: 750px) {
      top: 25%;
      max-width: 250px !important;
      padding: 20px;
    }
    @media screen and (max-width: 500px) {
      top: 25%;
      max-width: 300px !important;
      padding: 40px;
    }
  }
  .title {
    font-size: 22px;
    margin-bottom: 25px;
    @media screen and (max-height: 750px) {
      margin-bottom: 20px;
    }
  }
  .line {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .textInput {
      width: calc(100% - 10px);
      height: 40px;
      padding-left: 10px;
      &::placeholder {
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .lineCheck {
    display: flex;
    align-items: center;
    /* height: 40px; */
    margin-bottom: 20px;
    #checkbox {
      min-width: 15px;
      height: 15px;
      outline: none;
      margin-right: 10px;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 0.5rem;
        top: 0.18rem;
        width: 0.18rem;
        height: 0.6rem;
        border: solid white;
        border-width: 0 2px 2px 0;
        transition: background 250ms;
        transform: rotate(45deg);
      }
      &:checked {
        background: ${theme.colors.accent};
        border-color: white;
      }
    }
  }
  .lineCookies {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    /* padding-bottom: 30px; */
    /* border-bottom: 1px solid; */
    /* margin-bottom: 30px; */
  }

  .info {
    font-size: 12px;
    color: ${theme.colors.backgroundDark};
  }
  button {
    width: 140px;
    height: 45px;
    background-color: white;
    border: solid 1px grey;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color ease-in-out 0.2s, border ease-in-out 0.2s,
      color ease-in-out 0.2s;
    &.buttonAll {
      border: solid 3px ${theme.colors.backgroundDark};
      font-weight: bold;
    }
    &:hover {
      &.buttonAll {
        border-color: ${theme.colors.accent};
        color: ${theme.colors.accent};
      }
    }
    @media screen and (max-width: 500px) {
      width: 110px;
      min-height: 35px;
    }
    @media screen and (max-height: 750px) {
      width: 110px;
      min-height: 35px;
    }
  }
`
