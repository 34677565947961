import React, {useState, useEffect, useContext, useRef} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import {useLocation} from '@reach/router'
import Shop from '../components/Shop'
import ShopHalo from '../components/ShopHalo'
import Helmet from 'react-helmet'
import NewsletterPopup from '../components/NewsletterPopup'
import AppContext from '../utils/context/AppContext'
import {useScrollDirection} from 'react-use-scroll-direction'

function Layout({children, pageContext}) {
  const containerRef = useRef(null)
  const location = useLocation()
  const {isScrollingUp, isScrollingDown} = useScrollDirection()

  const {
    newsVisible,
    setNewsVisible,
    isPopupSlipmatVisible,
    scrollLoco,
    setScrollLoco
  } = useContext(AppContext)
  const [color, setColor] = useState('graphite-coton')

  // ROUTING
  useEffect(() => {
    if (location.pathname === '/') {
      setColor('halo')
    } else if (location.pathname === '/graphite-coton') {
      setColor('graphite-coton')
    } else if (location.pathname.includes('graphite-coton/graphite')) {
      setColor('graphite')
    } else if (location.pathname.includes('graphite-coton/coton')) {
      setColor('coton')
    } else {
      setColor('otherPage')
    }
  }, [location])

  let scroll = useRef(null)

  // INIT SMOOTH SCROLL ON HALO PAGE
  useEffect(() => {
    if (color === 'halo') {
      import('locomotive-scroll').then((locomotiveModule) => {
        scroll.current = new locomotiveModule.default({
          el: containerRef.current,
          smooth: true,
          getSpeed: true,
          multiplier: 1,
          touchMultiplier: 1.5,
          lerp: 0.05,
          scrollFromAnywhere: true,
          smartphone: {
            smooth: false
          },
          tablet: {
            smooth: false
          }
        })
        setScrollLoco(scroll.current)
      })
    } else {
      scroll.current?.stop()
      scroll.current?.destroy()
      setScrollLoco(null)
    }
    return () => {
      scroll.current?.stop()
      scroll.current?.destroy()
      setScrollLoco(null)
    }
  }, [color, setScrollLoco])

  // GET SMOOTH SCROLL DIRECTION ON HALO PAGE
  useEffect(() => {
    if (color === 'halo' && scrollLoco) {
      scrollLoco.on('scroll', (instance) => {
        if (instance.scroll.y < 20) {
          document
            .getElementsByClassName('navcontainer')[0]
            .classList.remove('scrollingDown')
          document
            .getElementsByClassName('navcontainer')[0]
            .classList.add('scrollingUp')
        } else {
          if (instance.speed > 6 || isScrollingDown) {
            document
              .getElementsByClassName('navcontainer')[0]
              .classList.add('scrollingDown')
            document
              .getElementsByClassName('navcontainer')[0]
              .classList.remove('scrollingUp')
          } else if (instance.speed < -6 || isScrollingUp) {
            document
              .getElementsByClassName('navcontainer')[0]
              .classList.remove('scrollingDown')
            document
              .getElementsByClassName('navcontainer')[0]
              .classList.add('scrollingUp')
          }
        }
      })
    } else {
      document
        .getElementsByClassName('navcontainer')[0]
        .classList.remove('scrollingDown')
      document
        .getElementsByClassName('navcontainer')[0]
        .classList.remove('scrollingUp')
    }
  }, [color, scrollLoco, isScrollingUp, isScrollingDown])

  // PAUSE SCROLL WHEN CART CONFIRM POPUP OPENED
  useEffect(() => {
    if (isPopupSlipmatVisible || newsVisible) {
      scroll.current?.stop()
    } else {
      scroll.current?.start()
    }
  }, [newsVisible, isPopupSlipmatVisible])

  return (
    <main className="main" ref={containerRef} data-scroll-container>
      <Helmet>
        <html lang="en" />
        <title>Cercle - Shop</title>
        <meta name="title" content="Cercle - Shop" />
        <meta
          name="description"
          content="Cercle is a livestream media dedicated to promoting artists & venues. We film and broadcast DJ sets & live performances on Facebook in carefully selected and unusual places."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${process.env.GATSBY_PROJECT_URL}/`}
        />
        <meta property="og:title" content="Cercle - Shop" />
        <meta
          property="og:description"
          content="Cercle is a livestream media dedicated to promoting artists & venues. We film and broadcast DJ sets & live performances on Facebook in carefully selected and unusual places."
        />
        <meta property="og:image" content="/banner.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`${process.env.GATSBY_PROJECT_URL}/`}
        />
        <meta property="twitter:title" content="Cercle - Shop" />
        <meta
          property="twitter:description"
          content="Cercle is a livestream media dedicated to promoting artists & venues. We film and broadcast DJ sets & live performances on Facebook in carefully selected and unusual places."
        />
        <meta property="twitter:image" content="/banner.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        />
        <body className={newsVisible ? 'noscroll' : ''} />
      </Helmet>
      <NewsletterPopup
        visible={newsVisible}
        hideNewslettersPopup={() => {
          if (typeof document !== 'undefined') {
            localStorage['alreadyVisited'] = true
          }
          setNewsVisible(false)
        }}
        showCookies={
          typeof document !== 'undefined' && !localStorage['alreadyVisited']
        }
      />
      <Navbar color={color} />
      {color === 'halo' ? <ShopHalo /> : <Shop color={color} />}
      {children}
      <Footer color={color} />
    </main>
  )
}

export default Layout
