import * as Styled from './NewsletterPopup.style'
import React from 'react'

import Cookies from 'universal-cookie'
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies'
import {useLocation} from '@reach/router'
import 'rodal/lib/rodal.css'

function NewsletterPopup({visible, hideNewslettersPopup, showCookies}) {
  const cookies = new Cookies()
  const location = useLocation()

  function answerCookies(answer) {
    if (typeof document !== 'undefined') {
      cookies.set('gatsby-gdpr-google-analytics', answer, {
        path: '/'
      })
      cookies.set('gatsby-gdpr-google-tagmanager', answer, {
        path: '/'
      })
      cookies.set('gatsby-gdpr-facebook-pixel', answer, {
        path: '/'
      })
      answer && initializeAndTrack(location)
      console.log('COOKIES ' + answer)
    }
    hideNewslettersPopup()
  }

  return (
    <Styled.RodalPopupContainer
      animation="fade"
      duration={500}
      visible={visible}
      showCloseButton={!showCookies}
      onClose={() => hideNewslettersPopup()}
      closeMaskOnClick={!showCookies}
    >
      <h2 className="title">About cookies...</h2>
      <div className="line">
        <div className="info">
          We use cookies to offer you the best experience on our website
        </div>
      </div>
      <div className="lineCookies">
        <button onClick={() => answerCookies(false)}>ONLY ESSENTIALS</button>
        <button className="buttonAll" onClick={() => answerCookies(true)}>
          ACCEPT ALL
        </button>
      </div>
    </Styled.RodalPopupContainer>
  )
}

export default NewsletterPopup
