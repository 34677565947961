import * as Styled from './SectionVideo.style'
import React, {useContext, useRef, useState} from 'react'

import Section from '../Section'
import ReactPlayer from 'react-player'
import AppContext from '../../utils/context/AppContext'
import PriceTag from '../PriceTag'
import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
import Hider from '../Hider'

function SectionVideo({data}) {
  const {scrollLoco} = useContext(AppContext)
  const [muted, setMuted] = useState(true)

  const handleScrollToShop = (e) => {
    e.stopPropagation()
    const shopEl = document.getElementById(
      encodeURIComponent('Choose your design!')
    )
    scrollLoco.scrollTo(shopEl, {offset: -100})
  }

  const handleScrollToSlipmat = (e) => {
    e.stopPropagation()
    const slipmatEl = document.getElementById(
      encodeURIComponent('Complete your vinyl design!')
    )
    console.log({slipmatEl})
    scrollLoco.scrollTo(slipmatEl, {offset: -100})
  }

  return (
    <Section>
      <Styled.SectionVideo
        className="container"
        id={encodeURIComponent(data.titleSection)}
      >
        <div className="videoContainer" onClick={(e) => handleScrollToShop(e)}>
          <ReactPlayer
            className="react-player"
            url={'https:' + data.video.file.url}
            playing={true}
            muted={muted}
            loop
            width="100%"
            height="100%"
            playsinline
          />
          <motion.div
            className="mutedContainer"
            onClick={(e) => {
              e.stopPropagation()
              setMuted(!muted)
            }}
          >
            {muted ? (
              <StaticImage
                src="../../images/halo/mute_on.svg"
                className="muteIcon"
                alt="mute"
                placeholder="blurred"
                layout="fixed"
                width={50}
                height={50}
              />
            ) : (
              <StaticImage
                src="../../images/halo/mute_off.svg"
                className="muteIcon"
                alt="mute"
                placeholder="blurred"
                layout="fixed"
                width={50}
                height={50}
                style={{opacity: 0.5}}
              />
            )}
          </motion.div>
        </div>

        {data.description && (
          <div className="description">
            {data.description}
            <div className="line">
              <Hider delay={0} />
              <StaticImage
                src="../../images/halo/line_1.png"
                alt="strike"
                placeholder="tracedSVG"
                width={606}
                height={30}
              />
            </div>
          </div>
        )}
        <div className="buttonsContainer">
          <div onClick={(e) => handleScrollToShop(e)} className="trackItem">
            <div className="firstLine">Shop</div>
            <div className="secondLine">Vinyl</div>
            <div className="priceTagContainer">
              <PriceTag price={'35'} marker={2} delay={0.15} />
            </div>
          </div>

          <div onClick={(e) => handleScrollToSlipmat(e)} className="trackItem">
            <div className="firstLine">Shop</div>
            <div className="secondLine">Slipmat</div>
            <div className="priceTagContainer">
              <PriceTag price={'15'} marker={1} delay={0.3} />
            </div>
          </div>
        </div>
      </Styled.SectionVideo>
    </Section>
  )
}

export default SectionVideo
