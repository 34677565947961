// import theme from '../../styles/theme/theme'
import * as Styled from './SectionFeaturedProduct.style'
import React, {useEffect} from 'react'
import Section from '../Section'
import CircleTitle from '../CircleTitle'
import {GatsbyImage, StaticImage} from 'gatsby-plugin-image'
import {renderRichText} from 'gatsby-source-contentful/rich-text'
import PriceTag from '../PriceTag'
import ProductHalo from '../ProductHalo'
import useShopifyBuy from '../../utils/hooks/useShopifyBuy'

function SectionFeaturedProduct({data}) {
  const {initShopifyBuy, clickBuyButton} = useShopifyBuy()

  useEffect(() => {
    initShopifyBuy(6868771668053, 'buy-button-6868771668053')
  }, [])

  return (
    <Section closerToTop>
      <Styled.SectionFeaturedProduct id={encodeURIComponent(data.titleSection)}>
        <CircleTitle title={data.titleSection} circleType={2} />
        <div className="container">
          <div className="imageContainer">
            <StaticImage
              src="../../images/halo/marker-square.png"
              alt="marker"
              placeholder="traceSVG"
              layout="fullWidth"
              quality={100}
              className="frame"
            />
            <ProductHalo
              // artistName="Slipmat"
              trackName="Slipmat"
              price="15"
              images={[data.image1.gatsbyImageData]}
              id_bigwax="6868771668053"
              contentful_id="6868771668053"
              directBuy
              style={{height: '100%'}}
              // isOutOfStock={isSlipmatOutOfStock}
            />
            {/* <GatsbyImage
              image={data.image1.gatsbyImageData}
              alt="slipmat recto"
              layout="fullWidth"
              className="image"
            /> */}
          </div>
          <div className="rightPanel">
            <div className="paragraphContainer">
              {renderRichText(data.paragraph, {})}
            </div>
            <div
              className="priceContainer"
              onClick={(e) => {
                e.stopPropagation()
                clickBuyButton(`buy-button-6868771668053`)
              }}
            >
              <PriceTag price={'BUY ' + data.price} marker={4} />
            </div>
            <div className="image2">
              <GatsbyImage
                image={data.image2.gatsbyImageData}
                alt="slipmat verso"
                style={{height: '100%', width: '100%'}}
                objectPosition="center"
                objectFit="cover"
              />
            </div>
          </div>
        </div>
      </Styled.SectionFeaturedProduct>
    </Section>
  )
}

export default SectionFeaturedProduct
