import * as Styled from './PriceTag.style'
import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import Hider from '../Hider'

function PriceTag({price, marker = 1, delay}) {
  return (
    <Styled.PriceTag $marker={marker}>
      {/* <Hider delay={delay} /> */}
      <span className="price">{price}€</span>
      {marker === 1 && (
        <StaticImage
          src="../../images/halo/marker-1.png"
          alt="marker"
          placeholder="traceSVG"
          layout="fullWidth"
          quality={100}
        />
      )}

      {marker === 2 && (
        <StaticImage
          src="../../images/halo/marker-2.png"
          alt="marker"
          placeholder="traceSVG"
          layout="fullWidth"
          quality={100}
        />
      )}
      {marker === 3 && (
        <StaticImage
          src="../../images/halo/marker-3.png"
          alt="marker"
          placeholder="traceSVG"
          layout="fullWidth"
          quality={100}
        />
      )}
      {marker === 4 && (
        <StaticImage
          src="../../images/halo/marker-4.png"
          alt="marker"
          placeholder="traceSVG"
          layout="fullWidth"
          quality={100}
        />
      )}
    </Styled.PriceTag>
  )
}

export default PriceTag
