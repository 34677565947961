import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const CircleTitle = styled.div`
  position: relative;
  width: fit-content;
  .title {
    position: relative;
    font-size: 1.8rem;
    font-family: ${theme.fonts.boogybrut};
    z-index: 50;
    &::first-letter {
      font-family: ${theme.fonts.pinyon};
      font-size: 2rem;
      margin-right: 0.2rem;
    }
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      font-size: 1.4rem;
      &::first-letter {
        font-size: 1.6rem;
      }
    }
  }
  .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: scale(1.8);
      ${
        '' /* @media screen and (max-width: ${theme.breakpoints.md}px) {
        transform: scale(1.4);
      } */
      }
    }
  }
`
