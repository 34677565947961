import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const ProductHalo = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid black;
  padding: 2rem;
  background-color: ${theme.colors.haloDark};
  color: ${theme.colors.haloLight};
  cursor: pointer;
  overflow: hidden;
  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    pointer-events: none;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .top {
      .title {
        font-family: ${theme.fonts.brut};
        font-size: 1.4rem;
      }
      .trackName {
        font-family: ${theme.fonts.brut};
        text-transform: uppercase;
        font-size: 1.1rem;
        letter-spacing: 0.1em;
      }
      .artistName {
        font-family: ${theme.fonts.boogybrut};
        font-size: 1.4rem;
        &:first-letter {
          font-family: 'Pinyon Script', cursive;
          margin-right: 2px;
        }
      }
    }
    .bottom {
      width: 100%;
      text-align: right;
      .price {
        font-family: ${theme.fonts.brut};
        font-size: 1.6rem;
      }
      .buy {
        font-family: ${theme.fonts.brut};
        font-size: 1.8rem;
      }
    }
  }
  > .shopify-buy__product ~ .shopify-buy__product {
    display: none !important;
  }
`
