import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const PopupSlipmat = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.7);
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    z-index: 30;
    background-color: black;
    color: ${theme.colors.haloLight};
    padding: 5rem 4rem;
    width: 96%;
    max-width: 450px;
    .cancel {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem 1.5rem;
      cursor: pointer;
      font-size: 1.5rem;
    }
    .title {
      position: relative;
      text-align: center;
      font-family: ${theme.fonts.boogybrut};
      font-size: 1.8rem;
      line-height: 1;
      &:first-letter {
        text-transform: uppercase;
        font-family: ${theme.fonts.pinyon};
        font-size: 2.2 rem;
        margin-right: 2px;
      }
      .line {
        filter: invert(1);
        position: absolute;
        bottom: -30px;
        right: -20px;
        width: 200px;
        height: 30px;
        }
      }
    }
    .imageContainer {
      width: 100%;
    }
    .add2product {
      position: relative;
      border: 1px solid ${theme.colors.haloLight};
      padding: 1rem 2rem;
      cursor: pointer;
      font-family: ${theme.fonts.brut};
      transition: all 0.3s ease;
      &:hover {
        background-color: ${theme.colors.haloLight};
        color: black;
      }
    }
    .add1product {
      position: relative;
      cursor: pointer;
      font-family: ${theme.fonts.boogybrut};
      &:first-letter {
        text-transform: uppercase;
        font-family: ${theme.fonts.pinyon};
        font-size: 1.2rem;
        margin-right: 2px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${theme.colors.haloLight};
      }
    }
    .buybuttons {
      display: none;
    }
  }
`
