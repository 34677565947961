import React from 'react'
import * as Styled from './Section.style'

function Section({children, style, closerToTop}) {
  return (
    <Styled.Section
      style={style}
      $closerToTop={closerToTop}
      data-scroll-section
    >
      {children}
    </Styled.Section>
  )
}

export default Section
