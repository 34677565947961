import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const SectionSlider = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 4rem;
  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    align-items: center;
  }
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .sliderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 66%;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      width: 100%;
    }
    .arrowContainer {
      padding: 4rem 0;
      width: 25px;
      cursor: pointer;
      &.left {
        transform: rotate(180deg);
      }
    }
    .slidesContainer {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;
      border: 1px solid #000;
      background-color: #000;
      .slide {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .react-player {
          video {
            min-height: 100%;
            max-height: 100%;
            min-width: 100%;
            max-width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .paragraphContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      width: 90%;
      font-size: 0.9rem;
    }
    p::first-letter {
      text-transform: uppercase;
      font-family: ${theme.fonts.pinyon};
      font-size: 1.4rem;
    }
    .priceTagContainer {
      position: absolute;
      bottom: -40px;
      right: -20px;
    }
  }
`
