import * as Styled from './Shop.style'
import React, {useContext, useState, useEffect} from 'react'

import theme from '../../styles/theme/theme'
import Product from '../Product'
import AppContext from '../../utils/context/AppContext.jsx'

function Shop({color}) {
  const {products} = useContext(AppContext)
  const [productPosition, setProductPosition] = useState('block')

  useEffect(() => {
    if (color === 'graphite-coton') {
      setProductPosition('static')
    } else {
      setTimeout(() => {
        setProductPosition('fixed')
      }, theme.transitionDuration)
    }
  }, [color])

  return (
    <Styled.Shop
      initial={{opacity: 0}}
      animate={{opacity: products.length > 0 ? 1 : 0}}
      style={{
        position: productPosition
      }}
    >
      {products.length > 0 && (
        <>
          <Product
            idBigwax={products[0].id_bigwax}
            titre={products[0].titre}
            images={products[0].images}
            fixedcolor="graphite"
            color={color}
            prix={products[0].prix}
          />
          <Product
            idBigwax={products[1].id_bigwax}
            titre={products[1].titre}
            images={products[1].images}
            fixedcolor="coton"
            color={color}
            prix={products[1].prix}
          />
        </>
      )}
    </Styled.Shop>
  )
}

export default Shop
