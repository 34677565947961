const theme = {
  baseFontSize: 14,
  contentWidth: '1200px',
  transitionDuration: 1000,
  marginAround: '1rem',

  breakpoints: {
    xxs: 320,
    xs: 375,
    sm: 480,
    mm: 640,
    md: 768,
    lg: 1024,
    vl: 1280,
    xl: 1440,
    xxl: 1600,
    hd: 1920
  },

  colors: {
    primary: '#0EFF85',
    accent: '#B55F55',
    backgroundDark: '#1D1D1D',
    backgroundLight: '#EFEADE',
    haloDark: '#20201E',
    haloLight: '#FBFAF8'
  },

  fonts: {
    heading: 'Brut Grotesque',
    body: 'Bely',
    couriernew: 'Courier New',
    brut: 'Brut Grotesque',
    pinyon: 'Pinyon Script',
    boogybrut: 'Boogy Brut'
  }
}

export default theme
