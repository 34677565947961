import * as Styled from './ShopHalo.style'
import React, {useContext} from 'react'

import AppContext from '../../utils/context/AppContext.jsx'
import SectionMachine from '../SectionMachine'
import FooterHalo from '../FooterHalo'
import PopupSlipmat from '../PopupSlipmat'
// import locomotiveScroll from 'locomotive-scroll'

function ShopHalo() {
  const {shopHaloData, productsHalo} = useContext(AppContext)

  const slipmatData = shopHaloData.listSections.find(
    (section) => section.sys.contentType.sys.id === 'sectionFeaturedProduct'
  )

  return (
    <Styled.ShopHalo
      initial={{opacity: 0}}
      animate={{opacity: productsHalo.length > 0 ? 1 : 0}}
    >
      {/* <div ref={containerRef} data-scroll-container> */}
      <PopupSlipmat slipmatData={slipmatData} />
      <SectionMachine listSections={shopHaloData.listSections} />
      {/* </div> */}
      <FooterHalo />
    </Styled.ShopHalo>
  )
}

export default ShopHalo
