import {createGlobalStyle} from 'styled-components'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  .noscroll{
    margin: 0; height: 100%; overflow: hidden
  }

  .darkPage{
    background-color: ${theme.colors.backgroundDark};
  }

  html {
      box-sizing: border-box;
      font-family: ${theme.fonts.couriernew};
  }

  html,
  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  button,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  a {
      padding: 0;
      margin: 0;
  }

  ::selection {
    color: ${theme.colors.cream};
    background-color: ${theme.colors.moss};
  }

  html, :root {
    font-size: ${theme.baseFontSize}px;
    -webkit-tap-highlight-color: transparent;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.heading};
    font-size: inherit;
  }

  h1 + h1,
  h2 + h2,
  h3 + h3,
  h4 + h4,
  h5 + h5,
  h6 + h6,
  p + p,
  ul + ul,
  ol + ol {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  ul, ol {
    padding-left: 1rem;
  }

  blockquote {
    display: block;
    padding: 0;
    margin: 0;
  }

  cite {
    display: block;
    font-style: normal;
  }

  a {
    color: currentColor;
    text-decoration: inherit;
    text-decoration-thickness: inherit;
    text-underline-offset: inherit;
  }

  button {
    border: none;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;

    &:disabled {
        cursor: default;
    }
  }

  img {
    user-select: none;
  }
  
  body > .shopify-buy-frame:not(:first-of-type) {
    display: none;
  }
  .shopify-buy-frame--cart{
    display: block !important;
  }
  .shopify-buy-frame--toggle{
    ${'' /* display: block !important; */}
  }
  .shopify-buy-frame--product > .shopify-buy__product ~ .shopify-buy__product{
      display: none !important;
  }
  .buybuttons {
    display: none;
  }
`

export default GlobalStyle
