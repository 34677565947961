import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const SectionFeaturedProduct = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 6rem;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    align-items: center;
  }
  .container {
    display: flex;
    align-items: flex-start;
    gap: 4rem;
    width: 100%;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      flex-direction: column;
    }
    .imageContainer {
      position: relative;
      width: 55%;
      aspect-ratio: 1/1;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        width: 90%;
        margin: auto;
      }
      .image {
        ${'' /* margin: 4rem; */}
      }
      .frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        scale: 1.1;
      }
    }
    .rightPanel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 45%;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        width: 100%;
      }
      .paragraphContainer {
        position: relative;
        display: flex;
        width: 85%;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          width: 90%;
          margin: 0 auto;
          font-size: 0.9rem;
        }
        p::first-letter {
          text-transform: uppercase;
          font-family: ${theme.fonts.pinyon};
          font-size: 1.4rem;
        }
        .priceTagContainer {
          position: absolute;
          bottom: -40px;
          right: -20px;
        }
      }
      .priceContainer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 1rem;
        cursor: pointer;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          margin-bottom: 3rem;
        }
      }
      .image2 {
        border: 1px solid #000;
        background-color: #000;
        width: 100%;
        aspect-ratio: 4/3;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          width: 90%;
          margin: auto;
        }
      }
    }
    .buybuttons {
      display: none;
    }
  }
`
