import React, {useContext, useEffect, useState} from 'react'
import * as Styled from './ProductHalo.style'
import {motion} from 'framer-motion'
import {GatsbyImage} from 'gatsby-plugin-image'
import AppContext from '../../utils/context/AppContext'
import useWindowSizes from '../../utils/hooks/useWindowSizes'
import useShopifyBuy from '../../utils/hooks/useShopifyBuy'
// import theme from '../../styles/theme/theme'

function ProductHalo({
  artistName,
  trackName,
  price,
  images,
  // id_b64,
  id_bigwax,
  contentful_id,
  action,
  style,
  directBuy
}) {
  const {initShopifyBuy, isOutOfStock, clickBuyButton} = useShopifyBuy()
  const {setIsPopupSlipmatVisible, setProductBuying} = useContext(AppContext)
  const {windowWidth} = useWindowSizes()
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    initShopifyBuy(id_bigwax, `buy-button-${id_bigwax}`)
  }, [])

  const cleanedPrice = () => {
    if (price.split('.')[1] === '00') {
      return price.split('.')[0]
    }
    return price
  }

  const handleClickProduct = (e) => {
    e.stopPropagation()
    if (isHover) {
      // BUY SLIPMAT ONLY
      if (directBuy && !isOutOfStock(`buy-button-${id_bigwax}`)) {
        clickBuyButton(`buy-button-${id_bigwax}`)
      }
      // BUY SLIPMAT WITH VINYLE
      else if (
        // IF VINYLE IN STOCK
        !isOutOfStock(`buy-button-${id_bigwax}`)
      ) {
        if (
          // IF SLIPMAT OUT OF STOCK, BUY VINYLE ONLY
          isOutOfStock(`buy-button-6868771668053`)
        ) {
          clickBuyButton(`buy-button-${id_bigwax}`)
        } else {
          // IF SLIPMAT IN STOCK, OPEN POPUP
          setIsPopupSlipmatVisible(true)
          setProductBuying(id_bigwax)
        }
      }
      // IF VINYLE OUT OF STOCK, DO NOTHING
      else {
        e.stopPropagation()
      }
    } else {
      setIsHover(true)
    }
  }

  return (
    <Styled.ProductHalo
      style={style}
      onMouseEnter={() => {
        if (windowWidth >= 768) {
          setIsHover(true)
        }
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      onClick={(e) => handleClickProduct(e)}
    >
      {/* OVERLAY 1 */}
      <motion.div
        initial={{
          opacity: 0,
          scale: 1,
          filter: 'grayscale(0%)'
        }}
        animate={{
          opacity: isHover ? 0.1 : 1,
          scale: isHover ? 1.08 : 1,
          filter: isHover ? 'grayscale(100%)' : 'grayscale(0%)'
        }}
        transition={{opacity: {duration: 0.3}, scale: {duration: 0.5}}}
        className="imageOverlay"
      >
        {images[0] && (
          <GatsbyImage
            className="image"
            image={images[0]}
            alt={trackName}
            layout="fullWidth"
            objectFit="cover"
            width="100%"
            height="100%"
          />
        )}
      </motion.div>
      {/* OVERLAY 1 */}
      <motion.div
        className="infoContainer"
        initial={{opacity: 0}}
        animate={{opacity: isHover ? 1 : 0}}
        id={contentful_id}
      >
        <div className="top">
          <div className="title">0.0.3.0 | HALO</div>
          <div>-</div>
          <div className="trackName">{trackName}</div>
          <div className="artistName">{artistName}</div>
        </div>
        <div className="bottom">
          <div className="price">{cleanedPrice()}€</div>
          <div className="buy">
            {isOutOfStock(`buy-button-${id_bigwax}`) ? 'OUT OF STOCK' : 'BUY'}
          </div>
        </div>
      </motion.div>
      <div className="buybuttons">
        <div id={`buy-button-${id_bigwax}`}></div>
      </div>
    </Styled.ProductHalo>
  )
}

export default ProductHalo
