import * as Styled from './FooterHalo.style'
import React from 'react'
import Section from '../Section'
import {Link} from 'gatsby'

function FooterHalo() {
  return (
    <Section>
      <Styled.FooterHalo id={'footer'}>
        <Link to="/terms-and-conditions">CGV</Link>
      </Styled.FooterHalo>
    </Section>
  )
}

export default FooterHalo
