import * as Styled from './CircleTitle.style'
import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import circleSmall from '../../images/halo/circle-small.png'
import circleBig from '../../images/halo/circle-big.png'
import Hider from '../Hider'
// import theme from '../../styles/theme/theme'

function CircleTitle({title, circleType}) {
  return (
    <Styled.CircleTitle>
      <Hider delay={0} bigger />
      <h2 className="title">{title}</h2>
      <div className="circle">
        {circleType === 1 ? (
          <img src={circleSmall} alt="circle-small" />
        ) : (
          <img src={circleBig} alt="circle-big" />
        )}
      </div>
    </Styled.CircleTitle>
  )
}

export default CircleTitle
