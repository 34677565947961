import * as Styled from './SectionGridTracks.style'
import React from 'react'
import Section from '../Section'
import {StaticImage} from 'gatsby-plugin-image'
import Hider from '../Hider'
// import theme from '../../styles/theme/theme'

function SectionGridTracks({data}) {
  return (
    <Section
      style={{
        padding: '3rem 3%'
      }}
    >
      <Styled.SectionGridTracks id={encodeURIComponent(data.titleSection)}>
        <div className="titleContainer">
          <span className="left">Tracklist</span>{' '}
          <span className="right">0.0.3.0 | HALO</span>
          <div className="line">
            <Hider delay={0} />
            <StaticImage
              src="../../images/halo/line_1.png"
              alt="strike"
              placeholder="tracedSVG"
              width={600}
              height={30}
            />
          </div>
        </div>
        <div className="tracksContainer">
          {data.listTracks.map((track, index) => {
            return (
              <a href={track.url} target="_blank" key={index} rel="noreferrer">
                <div className="trackItem">
                  <div className="artistName">{track.artistName}</div>
                  <div className="trackName">{track.trackName}</div>
                </div>
              </a>
            )
          })}
          {/* <div className="line">
            <StaticImage
              src="../../images/halo/line_1.png"
              alt="strike"
              placeholder="tracedSVG"
              width={606}
              height={30}
            />
          </div> */}
        </div>
      </Styled.SectionGridTracks>
    </Section>
  )
}

export default SectionGridTracks
