// import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const Footer = styled.div`
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  width: calc(100vw - 8%);
  font-size: 12px;
  color: ${({color}) =>
    color === 'graphite-coton' || color === 'graphite' || color === 'otherPage'
      ? '#fff'
      : '#000'};
  text-align: center;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  pointer-events: none;
  /* .mentions {
		display: flex;
		align-items: flex-end;
	} */
  img {
    transition: all 0.3s ease-in-out;
    filter: ${({color}) =>
      color === 'graphite-coton' ||
      color === 'coton' ||
      color === 'otherPage' ||
      color === 'halo'
        ? 'invert(0)'
        : 'invert(1)'};
    pointer-events: all;
    margin-top: 0.5rem;
    width: 25px;
    cursor: pointer;
  }
`
