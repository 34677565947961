import * as Styled from './Navbar.style'
import React, {useContext} from 'react'

import slugify from 'slugify'

import {Link} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'
import AppContext from '../../utils/context/AppContext.jsx'
import useScroll from '../../utils/hooks/useScroll'
import useWindowSizes from '../../utils/hooks/useWindowSizes'
import theme from '../../styles/theme/theme'

function Navbar({color}) {
  const {scrollY} = useScroll()
  const {windowHeight, windowWidth} = useWindowSizes()
  const {products, scrollLoco} = useContext(AppContext)

  function ProductsMenu({products, color}) {
    if (products?.length > 0) {
      return products.map((product, i) => {
        return (
          <Link
            to={
              // color === 'halo'
              //   ? '/graphite-coton'
              //   :
              color === product.titre.toLowerCase()
                ? '/graphite-coton'
                : '/graphite-coton/' + slugify(product.titre, {lower: true})
            }
            className={
              color === product.titre.toLowerCase()
                ? color === 'graphite'
                  ? 'nav-menu-item activeGraphite'
                  : 'nav-menu-item activeCoton'
                : 'nav-menu-item'
            }
            style={{
              color:
                color === 'graphite' ||
                (color === 'graphite-coton' &&
                  windowWidth < theme.breakpoints.md)
                  ? 'white'
                  : 'black'
            }}
            key={i}
          >
            {product.displayedTitle}
          </Link>
        )
      })
    }
    return null
  }

  return (
    <Styled.Navbar
      animate={{
        opacity:
          scrollY > windowHeight * 0.6 &&
          color !== 'otherPage' &&
          color !== 'halo'
            ? 0
            : 1
      }}
      style={{
        pointerEvents:
          scrollY > windowHeight * 0.6 &&
          color !== 'otherPage' &&
          color !== 'halo'
            ? 'none'
            : 'auto'
      }}
    >
      <div
        className="navcontainer"
        style={{
          backgroundColor:
            color === 'otherPage' ? 'rgba(239, 234, 222, 0.9)' : 'transparent'
        }}
      >
        <div className="nav-left-container">
          <Link
            to="/"
            style={{
              height: '100%'
            }}
          >
            <div
              onClick={() => {
                if (color === 'halo') {
                  scrollLoco.scrollTo('top')
                }
              }}
              style={{
                height: '100%',
                filter:
                  color === 'graphite-coton' || color === 'graphite'
                    ? 'invert(0)'
                    : 'invert(1)'
              }}
            >
              <StaticImage
                src="../../images/icon.png"
                alt="Logo Cercle"
                className="logo"
                placeholder="tracedSVG"
                width={60}
                height={60}
                style={{height: '100%', width: '100%'}}
                imgStyle={{objectFit: 'contain'}}
              />
            </div>
          </Link>
          <div className="product-container">
            <Link
              to={'/'}
              className={
                color === 'halo' ? 'nav-menu-item activeHalo' : 'nav-menu-item'
              }
              style={{
                color:
                  color === 'graphite' ||
                  (color === 'graphite-coton' &&
                    windowWidth < theme.breakpoints.md)
                    ? 'white'
                    : 'black'
              }}
            >
              0.0.3.0 HALO
            </Link>
            {/* {color === 'halo' ? (
              <Link to="/graphite-coton">GRAPHITE & COTON</Link>
            ) : ( */}
            <ProductsMenu products={products} color={color} />
            {/* )} */}
          </div>
        </div>
        <div className="nav-right-container">
          {/* <button onClick={() => setNewsVisible(true)}>➞</button> */}
        </div>
      </div>
    </Styled.Navbar>
  )
}

export default Navbar
