import React, {useEffect, useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'

var base64 = require('base-64')

const AppContext = React.createContext({
  products: []
})

function AppContextProvider({children}) {
  const [newsVisible, setNewsVisible] = useState(false)
  const [isPopupSlipmatVisible, setIsPopupSlipmatVisible] = useState(false)
  const [productBuying, setProductBuying] = useState(null)
  const [products, setProducts] = useState([])
  const [productsHalo, setProductsHalo] = useState([])
  const [shopifyInstance, setShopifyInstance] = useState(null)
  const [scrollLoco, setScrollLoco] = useState(null)

  const buyButtonOptions = {
    product: {
      iframe: false,
      styles: {
        product: {
          '@media (min-width: 601px)': {
            'max-width': 'calc(25% - 20px)',
            'margin-left': '20px',
            'margin-bottom': '50px'
          }
        },
        title: {
          'font-weight': 'normal',
          color: '#000000'
        },
        button: {
          'font-family': 'Arvo, serif',
          ':hover': {
            'background-color': '#000000'
          },
          'background-color': '#000000',
          ':focus': {
            'background-color': '#000000'
          },
          'border-radius': '0px',
          'padding-left': '30px',
          'padding-right': '30px'
        },
        price: {
          color: '#000000'
        },
        compareAt: {
          color: '#000000'
        },
        unitPrice: {
          color: '#000000'
        },
        description: {
          color: '#000000'
        }
      },
      contents: {
        img: false,
        title: false,
        price: false
      },
      text: {
        button: 'BUY NOW'
      },
      googleFonts: ['Arvo']
    },
    productSet: {
      styles: {
        products: {
          '@media (min-width: 601px)': {
            'margin-left': '-20px'
          }
        }
      }
    },
    modalProduct: {
      contents: {
        img: false,
        imgWithCarousel: true
      },
      styles: {
        product: {
          '@media (min-width: 601px)': {
            'max-width': '100%',
            'margin-left': '0px',
            'margin-bottom': '0px'
          }
        },
        button: {
          'font-family': 'Arvo, serif',
          ':hover': {
            'background-color': '#000000'
          },
          'background-color': '#000000',
          ':focus': {
            'background-color': '#000000'
          },
          'border-radius': '0px',
          'padding-left': '30px',
          'padding-right': '30px'
        },
        title: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '28px',
          color: '#000000'
        },
        price: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '24px',
          color: '#000000'
        },
        compareAt: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '20.4px',
          color: '#000000'
        },
        unitPrice: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '20.4px',
          color: '#000000'
        },
        description: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '16px',
          color: '#000000'
        }
      },
      googleFonts: ['Arvo'],
      text: {
        button: 'Add to cart'
      }
    },
    modal: {
      styles: {
        modal: {
          'background-color': '#fffdfd'
        }
      }
    },
    option: {
      styles: {
        label: {
          'font-family': 'Arvo, serif',
          color: '#000000'
        },
        select: {
          'font-family': 'Arvo, serif'
        }
      },
      googleFonts: ['Arvo']
    },
    cart: {
      styles: {
        button: {
          'font-family': 'Arvo, serif',
          ':hover': {
            'background-color': '#000000'
          },
          'background-color': '#000000',
          ':focus': {
            'background-color': '#000000'
          },
          'border-radius': '0px'
        },
        title: {
          color: '#000000'
        },
        header: {
          color: '#000000'
        },
        lineItems: {
          color: '#000000'
        },
        subtotalText: {
          color: '#000000'
        },
        subtotal: {
          color: '#000000'
        },
        notice: {
          color: '#000000'
        },
        currency: {
          color: '#000000'
        },
        close: {
          color: '#000000',
          ':hover': {
            color: '#000000'
          }
        },
        empty: {
          color: '#000000'
        },
        noteDescription: {
          color: '#000000'
        },
        discountText: {
          color: '#000000'
        },
        discountIcon: {
          fill: '#000000'
        },
        discountAmount: {
          color: '#000000'
        }
      },
      text: {
        total: 'Total',
        notice: '',
        button: 'PAY'
      },
      contents: {
        note: true
      },
      popup: false,
      googleFonts: ['Arvo']
    },
    toggle: {
      styles: {
        toggle: {
          'font-family': 'Arvo, serif',
          'background-color': '#000000',
          ':hover': {
            'background-color': '#000000'
          },
          ':focus': {
            'background-color': '#000000'
          }
        }
      },
      googleFonts: ['Arvo']
    },
    lineItem: {
      styles: {
        variantTitle: {
          color: '#000000'
        },
        title: {
          color: '#000000'
        },
        price: {
          color: '#000000'
        },
        fullPrice: {
          color: '#000000'
        },
        discount: {
          color: '#000000'
        },
        discountIcon: {
          fill: '#000000'
        },
        quantity: {
          color: '#000000'
        },
        quantityIncrement: {
          color: '#000000',
          'border-color': '#000000'
        },
        quantityDecrement: {
          color: '#000000',
          'border-color': '#000000'
        },
        quantityInput: {
          color: '#000000',
          'border-color': '#000000'
        }
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      typeof document !== 'undefined'
        ? localStorage['alreadyVisited']
          ? setNewsVisible(false)
          : setNewsVisible(true)
        : setNewsVisible(false)
    }, 3000)
  }, [])

  const graphql_products = useStaticQuery(graphql`
    {
      allShopifyProduct {
        edges {
          node {
            variants {
              price
            }
            title
            shopifyId
            descriptionHtml
            totalInventory
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                    formats: [WEBP, JPG]
                    breakpoints: [550, 750, 1080, 1366, 1920]
                    quality: 100
                  )
                }
              }
            }
            images {
              product {
                featuredImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                        formats: [WEBP, JPG]
                        breakpoints: [550, 750, 1080, 1366, 1920]
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      allContentfulPageShop {
        edges {
          node {
            contentful_id
            listeProduits {
              bigwaxId
              categories
              titre
              shortDescription {
                shortDescription
              }
            }
          }
        }
      }
      allContentfulPage(
        filter: {
          contentful_id: {eq: "2SuHw3XA2HP256Uz5Esd0b"}
          node_locale: {eq: "fr"}
        }
      ) {
        edges {
          node {
            listSections {
              ... on ContentfulSectionVideo {
                id
                titleSection
                description
                video {
                  file {
                    url
                  }
                }
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              ... on ContentfulSectionGridTracks {
                id
                listTracks {
                  artistName
                  trackName
                  url
                  contentful_id
                }
                titleSection
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              ... on ContentfulSectionSlider {
                id
                titleSection
                price
                paragraph {
                  raw
                }
                images {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                    formats: [WEBP, JPG]
                    breakpoints: [550, 750, 1080, 1366, 1920]
                    quality: 100
                  )
                  file {
                    url
                  }
                }
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              ... on ContentfulSectionShop {
                id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                titleSection
                title
                paragraph {
                  raw
                }
                paragraph2 {
                  raw
                }
                listShopItems {
                  edition
                  bigwaxId
                  track {
                    artistName
                    trackName
                    url
                    contentful_id
                  }
                }
              }
              ... on ContentfulSectionFeaturedProduct {
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                id
                titleSection
                title
                price
                paragraph {
                  raw
                }
                bigwaxId
                image2 {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                    formats: [WEBP, JPG]
                    breakpoints: [550, 750, 1080, 1366, 1920]
                    quality: 100
                  )
                }
                image1 {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                    formats: [WEBP, JPG]
                    breakpoints: [550, 750, 1080, 1366, 1920]
                    quality: 100
                  )
                }
              }
            }
            node_locale
          }
        }
      }
    }
  `)
  // MERGE CONTENTFUL AND SHOPIFY PRODUCTS // SHOP GRAPHITE COTON
  useEffect(() => {
    const contentful_products =
      graphql_products.allContentfulPageShop.edges[0].node.listeProduits
    const results = contentful_products.map((contentful_product) => {
      let id_b64 = base64.encode(
        'gid://shopify/Product/' + contentful_product.bigwaxId
      )
      let id = 'gid://shopify/Product/' + contentful_product.bigwaxId
      let current_shopify_product =
        graphql_products.allShopifyProduct.edges.filter((shopify_product) => {
          return shopify_product.node.shopifyId === id
        })

      return {
        titre: contentful_product.titre,
        displayedTitle: current_shopify_product[0].node.title,
        id_bigwax: contentful_product.bigwaxId,
        id_b64: id_b64,
        categories: contentful_product.categories,
        prix: current_shopify_product[0].node.variants[0].price,
        stock: current_shopify_product[0].node.totalInventory,
        images: current_shopify_product[0].node.images.map(
          (image) =>
            image.product.featuredImage.localFile.childImageSharp
              .gatsbyImageData
        ),
        description: current_shopify_product[0].node.descriptionHtml,
        shortDescription: contentful_product.shortDescription.shortDescription
      }
    })

    setProducts(results)
  }, [graphql_products])

  // MERGE CONTENTFUL AND SHOPIFY PRODUCTS // SHOP HALO
  const shopHaloData = graphql_products.allContentfulPage.edges[0].node
  useEffect(() => {
    const contentful_products = shopHaloData.listSections.filter(
      (section) => section.sys?.contentType.sys.id === 'sectionShop'
    )[0].listShopItems
    const results = contentful_products.map((contentful_product) => {
      let id_b64 = base64.encode(
        'gid://shopify/Product/' + contentful_product.bigwaxId
      )
      let id = 'gid://shopify/Product/' + contentful_product.bigwaxId
      let current_shopify_product =
        graphql_products.allShopifyProduct.edges.filter((shopify_product) => {
          return shopify_product.node.shopifyId === id
        })

      return {
        artistName: contentful_product.track.artistName,
        trackName: contentful_product.track.trackName,
        shopifyTitle: current_shopify_product[0].node.title,
        id_bigwax: contentful_product.bigwaxId,
        id_b64: id_b64,
        contentful_id: contentful_product.track.contentful_id,
        price: current_shopify_product[0].node.variants[0].price,
        stock: current_shopify_product[0].node.totalInventory,
        images: current_shopify_product[0].node.images.map(
          (image) =>
            image.product.featuredImage.localFile.childImageSharp
              .gatsbyImageData
        )
        // images: current_shopify_product[0].node.images.map(
        //   (image) => image.localFile.childImageSharp.gatsbyImageData
        // )
        // description: current_shopify_product[0].node.descriptionHtml,
        // shortDescription: contentful_product.shortDescription.shortDescription
      }
    })
    setProductsHalo(results)
  }, [graphql_products, shopHaloData.listSections])

  return (
    <AppContext.Provider
      value={{
        shopHaloData,
        products,
        productsHalo,

        isPopupSlipmatVisible,
        setIsPopupSlipmatVisible,
        productBuying,
        setProductBuying,

        newsVisible,
        setNewsVisible,

        buyButtonOptions,
        shopifyInstance,
        setShopifyInstance,

        scrollLoco,
        setScrollLoco
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
export default AppContext
export {AppContextProvider}
