import * as Styled from './Footer.style'
import React from 'react'
import {Link} from 'gatsby'

// import AppContext from '../../utils/context/AppContext.jsx'

import newsletterIcon from '../../images/icon_newsletter.svg'
import spotifyIcon from '../../images/icon_spotify.svg'
import facebookIcon from '../../images/icon_facebook.svg'
import instagramIcon from '../../images/icon_instagram.svg'
import youtubeIcon from '../../images/icon_youtube.svg'
import infoIcon from '../../images/icon_info.svg'

function Footer({color}) {
  //   const {setNewsVisible} = useContext(AppContext)

  const socials = [
    {
      name: 'newsletter',
      link: 'https://www.cercle.io',
      icon: newsletterIcon
    },
    {
      name: 'facebook',
      link: 'https://www.facebook.com/cerclemusic',
      icon: facebookIcon
    },
    {
      name: 'instagram',
      link: 'https://www.instagram.com/cerclemusic/',
      icon: instagramIcon
    },
    {
      name: 'youtube',
      link: 'https://www.youtube.com/channel/UCPKT_csvP72boVX0XrMtagQ',
      icon: youtubeIcon
    },
    {
      name: 'spotify',
      link: 'https://www.spotify.com/user/cerclemusic?si=f6d47a99e4f948a6',
      icon: spotifyIcon
    }
  ]

  return (
    <Styled.Footer color={color}>
      {/* <div className="mentions">
				<Link to="/mentions-legales" className="footer-link">
					legal notices and conditions of sale
				</Link>
			</div> */}
      <div className="socialsContainer">
        {/* <div
				onClick={() => setNewsVisible(true)}
				key="newsletter"
				role="presentation"
			>
				<img src={newsletterIcon} alt="newsletter" />
			</div> */}
        {socials.map((social) => (
          <div key={social.name}>
            <a href={social.link} target="_blank" rel="noopener noreferrer">
              <img src={social.icon} alt={social.name} />
            </a>
          </div>
        ))}
        <div key="info">
          <Link to="/terms-and-conditions">
            <img src={infoIcon} alt="newsletter" />
          </Link>
        </div>
      </div>
    </Styled.Footer>
  )
}

export default Footer
