import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const Section = styled(motion.section)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${'' /* padding:7rem 3rem; */}
  padding: ${({$closerToTop}) =>
    $closerToTop ? '2rem 3rem 5rem 3rem ' : '5rem 3rem'};
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    padding: 3rem 5%;
  }
`
