import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const Shop = styled(motion.div)`
  display: flex;
  top: 0;
  align-items: center;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  z-index: -1;
  overflow: hidden;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    display: block;
    height: 100vh;
  }
`
