import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const PriceTag = styled.div`
  position: relative;
  width: 100px;
  height: 50px;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    width: 80px;
    height: 40px;
  }
  .price,
  .gatsby-image-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .gatsby-image-wrapper {
    transform: ${({$marker}) =>
      $marker === 3 || $marker === 4
        ? `scale(1.3) translateX(-2px) translateY(-4px)`
        : 'scale(1)'};
  }
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    vertical-align: middle;
    color: white;
    z-index: 10;
    text-align: center;
    bottom: 6px;
    left: 6px;
    font-family: ${theme.fonts.brut};
    white-space: nowrap;
    ${'' /* font-size: 1.9rem; */}
    font-size: ${({$marker}) => ($marker === 4 ? `1.5rem` : '1.9rem')};
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      font-size: 1.5rem;
      bottom: 1px;
    }
  }
`
