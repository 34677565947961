import * as Styled from './Hider.style'
import React from 'react'

function Hider({delay, bigger}) {
  // get random number between 2 and 4
  const random = Math.floor(Math.random() * 3) + 2
  return (
    <Styled.Hider
      style={{
        top: bigger ? '-30%' : '0',
        right: bigger ? '-45%' : '-15%',
        height: bigger ? '180%' : '100%'
      }}
      initial={{width: bigger ? '220%' : '160%'}}
      whileInView={{width: '0%'}}
      transition={{
        // duration: 10,
        duration: random,
        ease: [0.6, 0.05, -0.01, 0.9],
        delay: 0.15 + delay
      }}
      viewport={{once: true}}
    ></Styled.Hider>
  )
}

export default Hider
