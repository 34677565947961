import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const FooterHalo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fonts.brut};
`
