import * as Styled from './PopupSlipmat.style'
import React, {useContext, useEffect} from 'react'
import AppContext from '../../utils/context/AppContext'
import {document, window} from 'browser-monads'
import {GatsbyImage, StaticImage} from 'gatsby-plugin-image'
import useShopifyBuy from '../../utils/hooks/useShopifyBuy'
// import theme from '../../styles/theme/theme'

function PopupSlipmat({slipmatData}) {
  const {clickBuyButton} = useShopifyBuy()
  const {isPopupSlipmatVisible, setIsPopupSlipmatVisible, productBuying} =
    useContext(AppContext)

  // create a keyboard listener, when esc is pressed, close the popup
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsPopupSlipmatVisible(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Styled.PopupSlipmat
      initial={{opacity: 0}}
      animate={{opacity: isPopupSlipmatVisible ? 1 : 0}}
      style={{pointerEvents: isPopupSlipmatVisible ? 'all' : 'none'}}
      onClick={(e) => {
        e.stopPropagation()
        clickBuyButton(`buy-button-${productBuying}`)
        setIsPopupSlipmatVisible(false)
      }}
    >
      <div className="container">
        <div
          className="cancel"
          onClick={(e) => {
            e.stopPropagation()
            setIsPopupSlipmatVisible(false)
          }}
        >
          x
        </div>
        <div className="title">
          Complete your vinyl design with the matching Cercle slipmat for 15€.
          <div className="line">
            <StaticImage
              src="../../images/halo/line_1.png"
              alt="strike"
              placeholder="tracedSVG"
              width={600}
              height={30}
            />
          </div>
        </div>
        <div className="imageContainer">
          <GatsbyImage
            image={slipmatData.image1.gatsbyImageData}
            alt={'Slipmat'}
            layout="fullWidth"
            className="image"
          />
        </div>
        <div
          className="add2product"
          onClick={(e) => {
            e.stopPropagation()
            clickBuyButton(`buy-button-${productBuying}`)
            setTimeout(() => {
              clickBuyButton(`buy-button-6868771668053`)
            }, 1000)
            setIsPopupSlipmatVisible(false)
          }}
        >
          ADD TO CART
        </div>
        <div
          className="add1product"
          onClick={(e) => {
            e.stopPropagation()
            clickBuyButton(`buy-button-${productBuying}`)
            setIsPopupSlipmatVisible(false)
          }}
        >
          No thanks
        </div>
        {/* <div className="buybuttons">
          <div id={`buy-button-slipmat`}></div>
          <div id={`buy-button-${productBuying}`}></div>
        </div> */}
      </div>
    </Styled.PopupSlipmat>
  )
}

export default PopupSlipmat
