import React from 'react'
import SectionGridTracks from './SectionGridTracks'
import SectionSlider from './SectionSlider'
import SectionVideo from './SectionVideo'
import SectionShop from './SectionShop'
import SectionFeaturedProduct from './SectionFeaturedProduct'

function SectionMachine({listSections}) {
  return listSections.map((section, index) => {
    // const isEven = index % 2 === 0

    switch (section.sys?.contentType.sys.id) {
      case 'sectionVideo':
        return <SectionVideo key={section.id + '#' + index} data={section} />

      case 'sectionGridTracks':
        return (
          <SectionGridTracks key={section.id + '#' + index} data={section} />
        )

      case 'sectionSlider':
        return <SectionSlider key={section.id + '#' + index} data={section} />

      case 'sectionShop':
        return <SectionShop key={section.id + '#' + index} data={section} />

      case 'sectionFeaturedProduct':
        return (
          <SectionFeaturedProduct
            key={section.id + '#' + index}
            data={section}
          />
        )

      default:
        return null
    }
  })
}

export default SectionMachine
