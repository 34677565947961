import * as Styled from './SectionShop.style'
import React, {useContext, useState} from 'react'
import Section from '../Section'
import AppContext from '../../utils/context/AppContext'
import ProductHalo from '../ProductHalo'
import CircleTitle from '../CircleTitle'
import {renderRichText} from 'gatsby-source-contentful/rich-text'
import {motion} from 'framer-motion'
// import theme from '../../styles/theme/theme'

function SectionShop({data}) {
  const {productsHalo} = useContext(AppContext)
  const [moreDetails, setMoreDetails] = useState(false)

  return (
    <Section closerToTop>
      <Styled.SectionShop id={encodeURIComponent(data.titleSection)}>
        <div className="titleContainer">
          <CircleTitle title={data.title} circleType={1} />
        </div>
        <div
          className="paragraphsContainer"
          onClick={() => setMoreDetails(!moreDetails)}
        >
          <div className="paragraphContainer firstletter">
            {renderRichText(data.paragraph, {})}
          </div>
          <div
            className="paragraphContainer"
            style={{color: 'black', fontWeight: 'bold'}}
          >
            {moreDetails ? '- details' : '+ details'}
          </div>
          <motion.div
            initial={{
              opacity: 0,
              height: 0
            }}
            animate={{
              opacity: moreDetails ? 1 : 0,
              height: moreDetails ? 'auto' : 0
            }}
            transition={{
              duration: 0.5,
              ease: 'easeInOut'
            }}
            className="paragraphContainer moreDetails"
          >
            {renderRichText(data.paragraph2, {})}
          </motion.div>
        </div>
        <div className="productsContainer">
          {productsHalo.map((product, i) => (
            <ProductHalo {...product} key={i} />
          ))}
        </div>
      </Styled.SectionShop>
    </Section>
  )
}

export default SectionShop
