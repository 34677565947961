import * as Styled from './Product.style'
import React, {useState, useEffect, useContext} from 'react'

import theme from '../../styles/theme/theme'
import {Link} from 'gatsby'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import slugify from 'slugify'
import {motion, AnimatePresence} from 'framer-motion'
import useWindowSizes from '../../utils/hooks/useWindowSizes'
import useScroll from '../../utils/hooks/useScroll'
import AppContext from '../../utils/context/AppContext'
import gifGraphite from '../../images/gifs/gif_graphite_square.gif'
import gifCoton from '../../images/gifs/gif_coton_square.gif'

function Product({idBigwax, titre, images, fixedcolor, color, prix}) {
  const {buyButtonOptions} = useContext(AppContext)
  const gatsbyImageData = getImage(images[0])
  const [mouseOnProduct, setMouseOnProduct] = useState(false)
  const [productWidth, setProductWidth] = useState('50%')
  const [productHeight, setProductHeight] = useState('100%')
  const [productImageWidth, setProductImageWidth] = useState('70%')

  const {windowWidth, windowHeight} = useWindowSizes()
  const {scrolled, scrollY} = useScroll()

  useEffect(() => {
    if (windowWidth > theme.breakpoints.md) {
      if (color === 'graphite-coton') {
        setProductWidth('50%')
        setProductHeight('100%')
        setProductImageWidth('100%')
      } else if (color === fixedcolor) {
        setProductWidth('100%')
        setProductHeight('100%')
        setProductImageWidth('60%')
      } else {
        setProductWidth('0%')
        setProductHeight('100%')
        setProductImageWidth('70%')
      }
    } else {
      if (color === 'graphite-coton') {
        setProductWidth('100%')
        setProductHeight('50%')
        setProductImageWidth('80%')
      } else if (color === fixedcolor) {
        setProductWidth('100%')
        setProductHeight('100%')
        setProductImageWidth('100%')
      } else {
        setProductWidth('100%')
        setProductHeight('0%')
        setProductImageWidth('0%')
      }
    }
  }, [color, fixedcolor, windowWidth])

  useEffect(() => {
    var scriptURL =
      'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        console.log('ShopifyBuyInit')
        ShopifyBuyInit()
      } else {
        loadScript()
      }
    } else {
      loadScript()
    }
    function loadScript() {
      var script = document.createElement('script')
      script.async = true
      script.src = scriptURL
      ;(
        document.getElementsByTagName('head')[0] ||
        document.getElementsByTagName('body')[0]
      ).appendChild(script)
      script.onload = ShopifyBuyInit
    }
    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
      })
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: idBigwax,
          node: document.getElementById(`buy-button-${idBigwax}`),
          moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
          options: buyButtonOptions
        })
      })
    }
  }, [color, buyButtonOptions, idBigwax])

  return (
    <Styled.Product
      initial={{
        width: windowWidth > theme.breakpoints.md ? '50%' : '100%',
        height: windowWidth > theme.breakpoints.md ? '100%' : '50%'
      }}
      animate={{
        width: productWidth,
        height: productHeight
      }}
      transition={{
        ease: 'linear',
        duration: theme.transitionDuration / 1000
      }}
      fixedcolor={fixedcolor}
      onHoverStart={() => setMouseOnProduct(true)}
      onHoverEnd={() => setMouseOnProduct(false)}
    >
      <motion.div
        animate={{opacity: scrolled ? 0.7 : 0}}
        className="image-dimer"
      />
      <motion.div
        className="image-container"
        animate={{
          width: productImageWidth
        }}
        transition={{
          duration: (theme.transitionDuration * 2.5) / 1000
        }}
        style={{
          pointerEvents: color !== 'graphite-coton' ? 'none' : 'all'
        }}
      >
        <Link to={'/graphite-coton/' + slugify(titre, {lower: true})}>
          <AnimatePresence>
            {mouseOnProduct ||
            (color === fixedcolor && scrollY <= windowHeight * 0.3) ? (
              <motion.img
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                key="gif"
                className="product-gif"
                src={fixedcolor === 'graphite' ? gifGraphite : gifCoton}
                alt={titre + ' gif'}
              />
            ) : (
              <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                key="image"
              >
                <GatsbyImage
                  image={gatsbyImageData}
                  alt={titre + ' image'}
                  imgClassName="image"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </Link>
      </motion.div>
      {color === 'graphite-coton' && (
        <motion.div
          animate={{
            opacity:
              mouseOnProduct || windowWidth <= theme.breakpoints.md ? 1 : 0
          }}
          style={{
            bottom:
              windowWidth <= theme.breakpoints.md
                ? windowHeight * 0.04
                : windowHeight * 0.06
          }}
          className="product-buttons-container"
        >
          <div className="buy-button-container">
            <div className="product-button" id={`buy-button-${idBigwax}`}></div>
          </div>
          <Link
            to={'graphite-coton/' + slugify(titre, {lower: true})}
            className="product-button"
          >
            DETAILS
          </Link>
        </motion.div>
      )}
    </Styled.Product>
  )
}

export default Product
