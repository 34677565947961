// import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const Hider = styled(motion.div)`
  position: absolute;
  top: 0;
  background: rgb(251, 250, 248);
  background: linear-gradient(
    260deg,
    rgba(251, 250, 248, 1) 0%,
    rgba(251, 250, 248, 1) 80%,
    rgba(251, 250, 248, 0) 100%
  );
  ${
    '' /* background: red;
  opacity: 0.5; */
  }
  z-index: 20;
`
