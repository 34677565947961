import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const Product = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  background-color: ${({fixedcolor}) => {
    return fixedcolor === 'graphite'
      ? theme.colors.backgroundDark
      : theme.colors.backgroundLight
  }};
  overflow: hidden;

  @media screen and (min-width: calc(${theme.breakpoints.md}px + 1px)) {
    min-height: 100vh;
  }
  .image-dimer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: ${({fixedcolor}) => {
      return fixedcolor === 'graphite'
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight
    }};
  }
  .image-container {
    position: relative;
    max-width: 1400px;
    @media screen and (min-width: ${theme.breakpoints.md}px) {
      height: 100%;
    }
    .product-gif {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
  .product-buttons-container {
    position: absolute;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    color: ${({fixedcolor}) => {
      return fixedcolor === 'graphite'
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight
    }};
    .product-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1rem;
      min-width: 6rem;
      height: 3rem;
      background-color: ${({fixedcolor}) => {
        return fixedcolor === 'graphite'
          ? theme.colors.backgroundLight
          : theme.colors.backgroundDark
      }};
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        font-size: 0.8rem;
        min-width: 4.5rem;
        height: 2rem;
      }
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: invert(100%);
        background-color: transparent;
        border: 1px solid
          ${({fixedcolor}) => {
            return fixedcolor === 'graphite'
              ? theme.colors.backgroundDark
              : theme.colors.backgroundLight
          }};
      }
      * {
        width: 100%;
        height: 100%;
      }
    }
  }
`
