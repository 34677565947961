import * as Styled from './SectionSlider.style'
import React, {useState} from 'react'
import Section from '../Section'
import {GatsbyImage, StaticImage} from 'gatsby-plugin-image'
import {motion, AnimatePresence} from 'framer-motion'
// import theme from '../../styles/theme/theme'
import {renderRichText} from 'gatsby-source-contentful/rich-text'
import PriceTag from '../PriceTag'
import ReactPlayer from 'react-player'
import {useSwipeable} from 'react-swipeable'

function SectionSlider({data}) {
  const [currentSlide, setCurrentSlide] = useState(0)

  const handleNextSlide = () => {
    if (currentSlide < data.images.length - 1) {
      setCurrentSlide(currentSlide + 1)
    } else {
      setCurrentSlide(0)
    }
  }
  const handlePreviousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    } else {
      setCurrentSlide(data.images.length - 1)
    }
  }

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log('User Swiped!', eventData),
    onSwipedRight: () => handleNextSlide(),
    onSwipedLeft: () => handlePreviousSlide()
  })

  return (
    <Section closerToTop>
      <Styled.SectionSlider id={encodeURIComponent(data.titleSection)}>
        {/* SLIDER */}
        <div className="sliderContainer">
          <div className="arrowContainer left" onClick={handlePreviousSlide}>
            <StaticImage
              src="../../images/halo/arrow.png"
              alt="arrow-right"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="slidesContainer" {...handlers}>
            <AnimatePresence exitBeforeEnter>
              {data.images.map((img, i) => {
                if (currentSlide === i) {
                  return (
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                      className="slide"
                      key={i}
                    >
                      {img.gatsbyImageData ? (
                        <GatsbyImage
                          image={img.gatsbyImageData}
                          alt={'image'}
                          style={{height: '100%', width: '100%'}}
                          objectPosition="center"
                          objectFit="cover"
                        />
                      ) : (
                        <ReactPlayer
                          className="react-player"
                          url={'https:' + img.file.url}
                          playing={true}
                          muted
                          loop
                          width="100%"
                          height="100%"
                          playsinline
                        />
                      )}
                    </motion.div>
                  )
                } else {
                  return null
                }
              })}
            </AnimatePresence>
          </div>
          <div className="arrowContainer" onClick={handleNextSlide}>
            <StaticImage
              src="../../images/halo/arrow.png"
              alt="arrow-right"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </div>
        {/* SLIDER */}
        {/* PARAGRAPH */}
        <div className="paragraphContainer">
          {renderRichText(data.paragraph, {})}
          {/* <div className="priceTagContainer">
            <PriceTag price={data.price} />
          </div> */}
        </div>
        {/* PARAGRAPH */}
      </Styled.SectionSlider>
    </Section>
  )
}

export default SectionSlider
