import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const SectionGridTracks = styled.div`
  width: 100%;
  display: flex;
  gap: 6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .titleContainer {
    position: relative;
    margin: auto;
    .left {
      font-size: 2rem;
      font-family: ${theme.fonts.boogybrut};
    }
    .right {
      font-size: 1.8rem;
      font-family: ${theme.fonts.brut};
    }
    .line {
      position: absolute;
      bottom: -40px;
      right: -100px;
      width: 300px;
      height: 30px;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        width: 200px;
        right: -30px;
      }
    }
  }
  .tracksContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0 auto;
    max-width: 700px;
    padding: 0 20px;
    padding-bottom: 60px;
    ${
      '' /* @media screen and (max-width: ${theme.breakpoints.md}px) {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
      align-items: center;
    } */
    }
    .trackItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 0.8;
      cursor: pointer;
      height: 100%;
      .artistName {
        font-family: ${theme.fonts.boogybrut};
        font-size: 1.8rem;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          font-size: 1.4rem;
        }
        &:first-letter {
          font-family: 'Pinyon Script', cursive;
          margin-right: 2px;
        }
      }
      .trackName {
        font-family: ${theme.fonts.brut};
        text-transform: uppercase;
        font-size: 1.3rem;
        letter-spacing: 0.05rem;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          font-size: 1rem;
        }
      }
    }
    .line {
      position: absolute;
      bottom: 0;
      right: -40px;
      width: 400px;
      height: 30px;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        width: 250px;
        height: 10px;
        right: 10px;
        bottom: 30px;
      }
    }
  }
`
