import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const Navbar = styled(motion.nav)`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 3;
  cursor: default;
  user-select: none;
  font-family: 'Brut Grotesque';
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    padding: 4px 0px;
  }
  & .navcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 ${theme.marginAround};
    max-width: ${theme.maxWidth};
    font-size: 1rem;
    ${
      '' /* &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(251, 250, 248, 1) 50%,
        rgba(249, 251, 248, 0.75) 75%,
        rgba(248, 251, 248, 0) 100%
      );
      opacity: 0;
    } */
    }
    transition: all 0.5s ease-in-out;
    &.scrollingDown {
      opacity: 0.3;
      &::before {
        transition: all 0.5s ease-in-out;
        content: '';
        position: absolute;
        top: -80px;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(251, 250, 248, 1);
        opacity: 0;
      }
    }
    &.scrollingUp {
      opacity: 1;
      &::before {
        transition: all 0.5s ease-in-out;
        content: '';
        position: absolute;
        top: -80px;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(251, 250, 248, 1);
        opacity: 0.7;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          top: 0px;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      &.scrollingDown {
        ${'' /* height: 80px; */}
        opacity: 0.3;
      }
      &.scrollingUp {
        ${'' /* height: 80px; */}
        opacity: 1;
      }
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.8rem;
    }
    .nav-left-container {
      display: flex;
      gap: 6px;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        flex-direction: column;
      }
      .product-container {
        display: flex;
        gap: 2rem;
        z-index: 20;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          gap: 1rem;
        }
        .nav-menu-item {
          ${'' /* margin: 0 1.5rem; */}
          transition: transform 0.3s;
          white-space: nowrap;
          &.activeCoton,
          &.activeHalo {
            font-weight: bolder;
            ${'' /* underline */}
            position: relative;
            &::after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: ${theme.colors.backgroundDark};
            }
            ${'' /* transform: scale(1.15); */}
          }
          &.activeGraphite {
            font-weight: bolder;
            ${'' /* underline */}
            position: relative;
            &::after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: ${theme.colors.backgroundLight};
            }
            ${'' /* transform: scale(1.15); */}
          }
          @media screen and (max-width: ${theme.breakpoints.md}px) {
            ${'' /* margin: 0 1rem; */}
            font-size: 0.8rem;
          }
        }
      }
    }
    .nav-right-container {
    }
  }
`
