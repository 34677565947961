import {useState, useEffect} from 'react'

function useScroll() {
  const [scrolled, setScrolled] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const treshold = 150
  let timeout

  useEffect(() => {
    document.addEventListener(
      'scroll',
      () => {
        scrollHandler()
        setScrollY(window.scrollY)
      },
      false
    )

    return function cleanup() {
      document.removeEventListener(
        'scroll',
        () => {
          scrollHandler()
          setScrollY(window.scrollY)
        },
        false
      )
    }
  })

  function scrollHandler() {
    debounce(() => {
      if (window.scrollY > treshold && !scrolled) {
        setScrolled(true)
      }
      if (window.scrollY <= treshold && scrolled) {
        setScrolled(false)
      }
    })
  }

  function debounce(fn) {
    if (timeout) {
      window.cancelAnimationFrame(timeout)
    }
    timeout = window.requestAnimationFrame(() => fn())
  }

  return {scrolled, scrollY}
}

export default useScroll
