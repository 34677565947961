import './src/styles/global.scss'
import './src/fonts/fonts.css'

import React from 'react'
import {AppContextProvider} from './src/utils/context/AppContext'
import GlobalStyle from './src/styles/theme/global'

export const wrapRootElement = ({element}) => {
  return (
    <AppContextProvider>
      <GlobalStyle />
      {element}
    </AppContextProvider>
  )
}
