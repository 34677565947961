import theme from '../../styles/theme/theme'
import styled from 'styled-components'
import {motion} from 'framer-motion'

export const ShopHalo = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  max-width: ${theme.contentWidth};
  margin: auto;
  z-index: -1;
  color: ${theme.colors.haloDark};
  padding-top: 120px;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    ${'' /* margin-top: 120px; */}
  }
`
