import {useContext} from 'react'
import AppContext from '../context/AppContext'

function useShopifyBuy(props) {
  const {buyButtonOptions, setShopifyInstance} = useContext(AppContext)

  const scriptURL =
    'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'

  const loadScript = (bigwaxId, elementId) => {
    var script = document.createElement('script')
    script.async = true
    script.src = scriptURL
    ;(
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    ).appendChild(script)
    script.onload = () => ShopifyBuyInit(bigwaxId, elementId)
  }

  const ShopifyBuyInit = (bigwaxId, elementId) => {
    let client = window.ShopifyBuy.buildClient({
      domain: process.env.GATSBY_SHOPIFY_STORE_URL,
      storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
    })
    window.ShopifyBuy.UI.onReady(client).then((ui) => {
      ui.createComponent('product', {
        id: bigwaxId,
        node: document.getElementById(elementId),
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
        options: buyButtonOptions
      })
      setShopifyInstance(ui)
    })
  }

  const ShopifyBuyDestroy = (elementId) => {
    const slipmatButton = document.getElementById(elementId)
    console.log({slipmatButton})
    // destroy all child elements of slipmatButton
    while (slipmatButton?.firstChild) {
      slipmatButton.removeChild(slipmatButton.firstChild)
      console.log('destroyed')
    }
  }

  // INIT SHOPIFY BUY BUTTONS
  const initShopifyBuy = (bigwaxId, elementId) => {
    ShopifyBuyDestroy(elementId)
    if (window.ShopifyBuy && window.ShopifyBuy.UI) {
      console.log('ShopifyBuyInit(', bigwaxId, elementId, ')')
      ShopifyBuyInit(bigwaxId, elementId)
    } else {
      console.log('loadScript()')
      loadScript(bigwaxId, elementId)
    }
  }

  const isOutOfStock = (elementId) => {
    // console.log('Checking stock availability...')
    const elBuyButton = document.getElementById(elementId)
    if (elBuyButton) {
      const isBuyButtonDisabled = elBuyButton.querySelector(
        '.shopify-buy__btn-disabled'
      )
      if (isBuyButtonDisabled) {
        return true
      } else {
        return false
      }
    }
  }

  const clickBuyButton = (buyButtonId) => {
    console.log('adding ', buyButtonId, ' to cart')
    console.log(document.getElementById(buyButtonId))
    const buyButton = document
      .getElementById(buyButtonId)
      .querySelector('.shopify-buy__btn')
    buyButton.click()
  }

  return {initShopifyBuy, isOutOfStock, clickBuyButton}
}

export default useShopifyBuy
