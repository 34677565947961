import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const SectionShop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    align-items: center;
    gap: 2rem;
  }
  .titleContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      justify-content: center;
    }
  }
  .paragraphsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      align-items: center;
      gap: 2rem;
    }
    .paragraphContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      max-width: 650px;
      cursor: pointer;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        width: 90%;
        max-width: none;
        font-size: 0.9rem;
      }
      &.firstletter {
        p:nth-of-type(2)::first-letter {
          text-transform: uppercase;
          font-family: ${theme.fonts.pinyon};
          font-size: 1.4rem;
        }
      }
      p:first-child {
        margin-bottom: 0.5rem;
        color: black;
      }
      p {
        margin: 0;
      }
    }
    .moreDetails {
      overflow: hidden;
      width: 90%;
      max-width: none;
    }
  }
  .productsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    width: 100%;
    max-width: ${theme.contentWidth};
    margin: auto;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      width: 90%;
    }
  }
`
