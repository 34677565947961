import theme from '../../styles/theme/theme'
import styled from 'styled-components'

export const SectionVideo = styled.div`
  width: 100%;
  .videoContainer {
    position: relative;
    background-color: white;
    border: 1px solid black;
    width: 80%;
    margin: auto;
    aspect-ratio: 16/9;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      width: 100%;
    }
    .react-player {
      video {
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .mutedContainer {
      position: absolute;
      right: 0px;
      bottom: -1px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      .muteIcon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 50px;
        height: 50px;
        filter: invert(1);
      }
    }
  }
  .shopLink {
    text-align: center;
    font-family: ${theme.fonts.brut};
    font-size: 1.5rem;
    border: 1px solid ${theme.colors.haloLDark};
    width: fit-content;
    padding: 10px 1.5rem;
    margin: auto;
    margin-top: 3rem;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${theme.colors.haloDark};
      color: white;
    }
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      margin-top: 3rem;
      max-width: 250px;
      margin: 0 auto;
      margin-top: 5rem;
    }
  }
  .description {
    position: relative;
    text-align: center;
    font-family: ${theme.fonts.boogybrut};
    font-size: 1.5rem;
    width: fit-content;
    margin: 3rem auto 0 auto;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      margin-top: 3rem;
      max-width: 250px;
      margin: 0 auto;
      margin-top: 5rem;
    }
    .line {
      position: absolute;
      bottom: -30px;
      right: -50px;
      width: 400px;
      height: 30px;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        width: 200px;
        height: 10px;
        right: -25px;
      }
    }
  }
  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 300px;
    margin: auto;
    margin-top: 7rem;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      max-width: 250px;
    }
    .trackItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 0.8;
      cursor: pointer;
      .firstLine {
        font-family: ${theme.fonts.brut};
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 0.05rem;
        margin-bottom: 4px;
      }
      .secondLine {
        font-family: ${theme.fonts.boogybrut};
        font-size: 1.8rem;
        &:first-letter {
          font-family: 'Pinyon Script', cursive;
          margin-right: 2px;
          font-size: 2rem;
        }
      }
      .priceTagContainer {
        position: relative;
      }
    }
  }
`
